import React from 'react'

const Plus = ({ isOpen }) => {
    return (
        <div className={isOpen ? 'plus plus__open' : 'plus'}>
            <div className="line"></div>
            <div className="line"></div>
            <div className={isOpen ? "plus plus__inner plus__open" : "plus plus__inner"}>
                <div className="line"></div>
                <div className="line"></div>
            </div>
        </div>
    )
}

export default Plus
