import useWindowDimensions from './app/getWindowDimensions'
import Desktop from './app/Desktop'
import Mobile from './app/Mobile'

function App() {

  const { width } = useWindowDimensions()

  return (
    <div className="App">
      {
        width > 500 ? (
          <Desktop />
        ) : (
            <Mobile />
          )
      }
      <div className='design'>дизайн и разработка: <a href="https://t.me/sosalasushku" className="link link__outer" target="_blank">@sosalasushku</a></div>
    </div>
  );
}

export default App;
