import React from 'react'
import { useRef } from 'react'
import Popup from '../components/Popup'
import Cover from '../components/Cover'
import mags from '../media/mags'
import Sending from '../media/sending.jpg'
import ArrowBig from '../media/arrow_big.svg'
import ArrowSmall from '../media/arrow_small.svg'
import Author from '../media/author.jpg'
import AuthorName from '../media/author_name.svg'
import Title from '../media/title.png'
import Stickers from '../media/stickers.jpg'

const Desktop = () => {

    const magsBlock = useRef(null)
    const infoBlock = useRef(null)

    const goToMags = () => {
        magsBlock.current?.scrollIntoView({ behavior: 'smooth' })
    }

    const goToInfo = () => {
        infoBlock.current?.scrollIntoView({ behavior: 'smooth' })
    }

    return (
        <div className='background'>
            <div className="about__container">
                <div className='container'>
                    <section className='about desktop'>
                        <div className='about__content'>
                            <div className="about__content__body">
                                <img src={Title} alt='Тюремный Вестник' loading="lazy" />
                                <div>
                                    «Тюремный вестник» — это новостной дайджест событий за месяц для политзаключённых,
                                    написанный нормальным языком. С мемами и подмигиванием, а также с великолепной обложкой,
                                    где отображено всё, что произошло за 30 дней.
                                </div>

                            </div>
                            <div className="btn__container btn__container-desktop">
                                <div className='btn btn-main' onClick={goToInfo}>узнать больше о проекте <img src={ArrowSmall} alt="" /></div>
                                <div className='btn btn-main' onClick={goToMags}>смотреть выпуски <img src={ArrowSmall} alt="" loading="lazy" /></div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>

            <div className='container' ref={infoBlock}>
                <section className="section">
                    <h3 className="heading h3">Как появился проект?</h3>
                    <div className='section__content'>
                        <div className="author">
                            <div className="author__img">
                                <img src={Author} alt="" loading="lazy" />
                            </div>
                            <div className="author__content">
                                <img src={AuthorName} alt="" loading="lazy" />
                                <p>Создатель «Тюремного вестника»</p>
                            </div>
                        </div>
                        <p>
                            Проект появился весной 2022, когда я узнал, что посадили моего знакомого, причём о его заключении
                            я узнал только через полгода. И я подумал, а что со мной будет, если меня посадят? Про меня так же,
                            как про моего приятеля, быстро все забудут, и я окажусь в информационной блокаде. Тогда я решил создать
                            проект, который будет помогать политзаключенным быть в контексте происходящего на воле.
                        </p>
                        <p>
                            Сначала я делал журнал для одного человека, а когда стало понятно, что идея рабочая, собрал команду, и мы
                            постепенно увеличили тираж до практически всех политзэков. Весной 2023 про нас написало несколько СМИ, что
                            помогло наладить фандрайзинг и вывело проект на самоокупаемость. Сейчас я практически делегировал все процессы
                            и выступаю только в роли издателя. Другими словами, у меня получилось создать маленькую институцию, которая будет
                            работать даже без моего участия, если со мной вдруг что-то случится.
                        </p>
                        <p>
                            Наша цель — дать <span className='link link__onhover popup-parent'>
                                <Popup device='desktop'>
                                    Кого считать политзэками — дискуссионный вопрос. Мы каждый месяц уточняем списки.
                                </Popup>каждому политзэку</span> понимание происходящего на воле.
                        </p>
                    </div>
                </section>
            </div>
            <div className='container'>
                <section className="section">
                    <h3 className="heading h3">Как происходит рассылка?</h3>
                    <div className='section__content'>
                        <div className="timeline__line hide"></div>
                        <div className="timeline__grid">
                            <div className="timeline__dot hide"></div>
                            <div className="timeline__dot hide"></div>
                            <div className="timeline__dot hide"></div>
                            <div className="timeline__dot hide"></div>
                            <div className="timeline__action">
                                Составляем списки политзаключённых
                            </div>
                            <div className="timeline__action">
                                <span className='link link__onhover popup-parent'>Отправляем<Popup device='desktop'>
                                    <img src={Sending} alt="" loading="lazy" />
                                </Popup></span> журнал бумажным письмом с конвертом для обратного ответа
                            </div>
                            <div className="timeline__action">
                                На основании ответов корректируем список
                            </div>
                            <div className="timeline__action">
                                Собираем аналитику по зонам, где пропускают, а где нет,
                                чтобы дальше юридически влиять на цензоров
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <div className='container'>
                <section className="section">
                    <div className='section__cards'>
                        <div className='section__card'>
                            <div className="section__card__container">
                               <h4 className="heading h4">Что с цензурой?</h4>
                                <div className="section__card__content">
                                    <p>
                                        Она есть, поэтому мы пытаемся писать таким языком, чтобы соблюдать законы РФ.
                                    </p>
                                    <p className='hyphen'>
                                        Есть колонии, где не пропускают письма с любыми формулировками. Мы собираем статистику
                                        по таким учреждениям для дальнейших судов с цензорами.
                                    </p>
                                </div> 
                            </div>
                            
                        </div>
                        <div className='section__card'>
                            <div className="section__card__container">
                                <h4 className="heading h4">Есть ли уже ответы?</h4>
                                <div className="section__card__content">
                                    Да, и много. Всем очень заходит идея проекта,
                                    и политзэки просят присылать им каждый месяц новый выпуск.
                                    Ответы можно почитать в моём <a href='https://t.me/LosevP' target='_blank' className='link link__outer'>телеграм-канале</a>  в комментариях к
                                    выпускам.
                                </div>
                            </div>
                        </div>
                        <div className='section__card'>
                            <div className="section__card__container">
                                <h4 className="heading h4">Что по деньгам?</h4>
                                <div className="section__card__content">
                                    Сначала оплачивал всё из своего кармана, но потом тиражи начали расти, и я стал просить
                                    денег у своих друзей и знакомых. Сейчас мы налаживаем фандрайзинг и планируем развиваться на донаты.
                                </div> 
                            </div>
                            
                        </div>
                    </div>
                </section>
            </div>
            <div className='container'>
                <section className="section">
                    <h3 className="heading h3">Как помочь проекту?</h3>
                    <div className='section__help'>
                        <a href="https://t.me/PTRLS" className="section__card section__card-1" target='_blank'>
                            <div className="section__card__container">
                                <h5 className="heading h5">Финансово</h5>
                                <div className="section__card__content">
                                    <p>
                                        Себестоимость одного выпуска&nbsp;— примерно 150&nbsp;рублей в&nbsp;месяц. Деньги идут на печать, бумагу, марки, плату за упаковку,
                                        конверты, менеджмент, обложку и прочее. Нам каждый месяц нужно больше средств, но не потому, что наши запросы растут,
                                        а&nbsp;потому что, к&nbsp;сожалению, политзаключённых с&nbsp;каждым месяцем становится всё больше. Донорам ежемесячно предоставляется
                                        отчёт до&nbsp;последней копейки.
                                    </p>
                                    <p>
                                        Если вы хотите поспособствовать развитию проекта,
                                    то <a href='https://t.me/PTRLS' target='_blank' className='link link__outer'>напишите мне</a>.
                                    </p>
                                </div>
                            </div>
                        </a>

                        <div className="section__card section__card-2">
                            <div className="section__card__container">
                                <h5 className="heading h5">Рассказать о проекте!</h5>
                                <div className="section__card__content">
                                    Это поможет привлечь доноров, увеличить
                                    тираж и приблизиться к главной цели — охватить всех
                                    политзэков России и прорвать информационную блокаду для них.
                                </div>
                            </div>
                        </div>
                        <a href='https://t.me/bashka_tova' target='_blank' className="section__card section__card-3">
                            <div className='section__card__img'>
                                <img src={Stickers} alt="Наш стикерпак" loading="lazy" />
                            </div>
                            <div className="section__card__container">
                                <h5 className="heading h5">Купить наши стикеры!</h5>
                                <div className="section__card__content">
                                    <p>
                                        Это поможет нам развиваться, а&nbsp;у&nbsp;вас будут уникальные наклейки, которые смогут украсить любое ваше устройство 
                                        и&nbsp;не&nbsp;только. Если вам понравятся наши стикеры, то&nbsp;мы сделаем целый стикербук! 
                                    </p>
                                    <p>
                                        По цене и всем подробностям приобретения <a href='https://t.me/bashka_tova' target='_blank' className='link link__outer'>пишите сюда</a>.
                                    </p>
                                    <p className='text-s'>
                                        * С каждого набора идет процент нашему художнику, который рисует нам великолепные обложки.
                                    </p>
                                </div>
                            </div>  
                        </a>
                    </div>
                </section>
            </div>


            <div className='container' ref={magsBlock}>
                <section className="section">
                    <h3 className="heading h3">Выпуски</h3>
                    <div className="section__mags">
                        {
                            mags.sort((a, b) => (b.id - a.id)).map(m => (
                                <Cover mag={m} />
                            ))
                        }
                    </div>
                </section>
            </div>
            <div className="container-dark">
                <div className="container">
                    <section className="section section__contacts">
                        <img className='hide' src={ArrowBig} alt="" loading="lazy" />
                        <div className="section__contacts__content">
                            <h4 className="heading h4">
                                Остались вопросы? Хотите помочь проекту?
                        </h4>
                            <div className="section__contacts__content__options">
                                <div className="section__contacts__content__option">
                                    <a href="https://t.me/PTRLS" target='_blank' className="link link__outer">написать мне</a>
                                    <p>Оформить подписку или задать вопрос</p>
                                </div>
                                <div className="section__contacts__content__option">
                                    <a href="https://t.me/LosevP" target='_blank' className="link link__outer">мой телеграм-канал</a>
                                    <p>Можно не только посмотреть выпуски, но и почитать ответы политзэков</p>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>

            </div>
        </div >
    )
}

export default Desktop
