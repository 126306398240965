import React from 'react'

const Popup = ({ device, visible, children, setState }) => {

    const close = e => {
        setState(false)
        e.stopPropagation()
    }

    return (
        <div className={`popup popup-${device} ${visible ? 'popup-open' : ''}`}>
            <div className="popup__content">
                {children}
            </div>
        </div >
    )
}

export default Popup
